import * as Types from './../constants/ActionType';

let initialState = [];

const admin = (state = initialState, action) => {

    switch(action.type) {
			case Types.SET_ADMINS:
				state = action.payload;
				return [...state];
			default:
					return [...state]; // copy new array and return it
    }

}

export default admin;