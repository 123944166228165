import * as Types from './../constants/ActionType';

export const setUserLogin = (user) => {
	return {
		type: Types.LOGIN,
		user
	}
}

export const signOut = () => {
	return {
		type: Types.SIGN_OUT
	}
}