import React from "react";
import LockIcon from '@material-ui/icons/LockOutlined';
import { Typography, FormControl, InputLabel, Input } from '@material-ui/core';

import { WrapForm, CompanyLogo, LoginButton, WrapSubmitButton, LoadingSubmit } from "./index.style";

const LoginForm = props => {

	const { onChangeInput, onSubmitLogin, loading, isActiveLoginButton } = props;

	return (
		<WrapForm>
			<CompanyLogo>
				<LockIcon />
			</CompanyLogo>
			<Typography>Đăng nhập</Typography>
			<form onSubmit={onSubmitLogin}>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="username">Tên đăng nhập</InputLabel>
					<Input onChange={onChangeInput}
								name="username"
								autoComplete="username"
								autoFocus />
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="password">Mật khẩu</InputLabel>
					<Input onChange={onChangeInput}
						name="password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>
				</FormControl>
				<WrapSubmitButton>
					<LoginButton
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={loading || !isActiveLoginButton}
					>
						Đăng nhập
					</LoginButton>
					{loading && <LoadingSubmit size={24} />}
				</WrapSubmitButton>
			</form>
		</WrapForm>
	);
}

export default LoginForm;