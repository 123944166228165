import * as Types from './../constants/ActionType';

let initialState = [];

const shop = (state = initialState, action) => {

    switch(action.type) {
			case Types.SET_SHOPS:
				state = action.shops;
				console.log(state);
				return [...state];
			default:
					return [...state]; // copy new array and return it
    }

}

export default shop;