import React, { Component, Fragment } from "react";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import pick from "lodash/pick";

// import style from "./style.css";
import { setUserLogin } from "../../actions/auth.action";
import LoginForm from "../../components/LoginForm";
import { login, setHeaders } from "../../utils/apiCaller";
import Notification from "../../utils/helpers/Notification";

class LoginContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isActiveLoginButton: false,
			username: "",
			password: "",
			loading: false
		}
	}

	componentWillMount() {
		const { auth, history } = this.props;
		if(auth.isLoggedIn) {
			history.push("/");
		}
	}

	onChangeInput = async (event) => {
		const target = event.target;
		await this.setState({
			[target.name]: target.value,
		});
		this.setState({
			isActiveLoginButton: this.state.username && this.state.password
		});
	}

	onSubmitLogin = async (event) => {
		event.preventDefault();
		this.setState({
			loading: true
		});
		const user = pick(this.state, ["username", "password"]);
		try {
			const resp = await login({
				body: user
			})
	
			if(resp.code) {
				this.setState({
					loading: false
				});
				Notification({
					type: "error",
					message: resp.description
				})
				return;
			}
	
			this.props.setUserLogin(resp);
			this.props.history.push("/contract");
			setHeaders(resp.accessToken);
		} catch(err) {
			this.setState({
				loading: false
			});
			Notification({
				type: "error",
				message: "Tài khoản không chính xác"
			});
			console.log(err)
		}
	}

	render () {
		const { loading, isActiveLoginButton } = this.state;

		return (
			<Fragment>
				<div id="login">
					<LoginForm
						onChangeInput={this.onChangeInput}
						onSubmitLogin={this.onSubmitLogin}
						loading={loading}
						isActiveLoginButton={isActiveLoginButton}
					/>
				</div>
				<NotificationContainer />
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		setUserLogin:(user) => {
			dispatch(setUserLogin(user));
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));