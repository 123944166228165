import * as Types from './../constants/ActionType';

let initialState = {
	salemans: [],
	collectors: [],
	shopOwners: []
};

const user = (state = initialState, action) => {

	switch(action.type) {
		case Types.SET_ALL_SALEMAN:
			return {
				...state,
				salemans: [...action.salemans]
			}
		case Types.SET_ALL_SHOP_OWNER:
			return {
				...state,
				shopOwners: [...action.shopOwners]
			}
		case Types.SET_ALL_COLLECTOR:
			return {
				...state,
				collectors: [...action.collectors]
			}
		default:
			return state;
	}

}

export default user;