import { combineReducers } from 'redux';
import sidebar from "./sidebar.reducer";
import auth from "./auth.reducer";
import shop from "./shop.reducer";
import user from "./user.reducer";
import collectorGroup from "./collectorGroup.reducer";
import borrower from "./borrower.reducer";
import collector from "./collector.reducer";
import admin from "./admin.reducer";
import area from "./area.reducer";

const appReducers = combineReducers({
	sidebar,
	auth,
	shop,
	user,
	collectorGroup,
	borrower,
	collector,
	admin,
	area
});

export default appReducers;