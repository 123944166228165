import asyncComponent from "../components/AsyncComponent";

const routes = [
	{
		path: "/",
		exact: true,
		main: asyncComponent(() => import("../containers/Contract").then(module => module.default))
	},
	{
		path: "/contract",
		exact: true,
		main: asyncComponent(() => import("../containers/Contract").then(module => module.default))
	},
	{
		path: "/user/admin",
		exact: true,
		main: asyncComponent(() => import("../containers/User/Admin").then(module => module.default))
	},
	{
		path: "/user/borrower",
		exact: true,
		main: asyncComponent(() => import("../containers/User/Borrower").then(module => module.default))
	},
	{
		path: "/user/collector",
		exact: true,
		main: asyncComponent(() => import("../containers/User/Collector").then(module => module.default))
	},
	{
		path: "/debt/schedule",
		exact: true,
		main: asyncComponent(() => import("../containers/DebtSchedule").then(module => module.default))
	},
	{
		path: "/area",
		exact: true,
		main: asyncComponent(() => import("../containers/Area").then(module => module.default))
	},
	{
		path: "/collector-group",
		exact: true,
		main: asyncComponent(() => import("../containers/CollectorGroup").then(module => module.default))
	},
	{
		path: "/me/change-password",
		exact: true,
		main: asyncComponent(() => import("../containers/Me/ChangePassword").then(module => module.default)),
	},
	{
		path: "/backup-data",
		exact: true,
		main: asyncComponent(() => import("../containers/BackupData").then(module => module.default)),
	},
	{
		path: "/import-excel",
		exact: true,
		main: asyncComponent(() => import("../containers/ImportExcel").then(module => module.default))
	},
	{
		path: "/login",
		exact: true,
		main: asyncComponent(() => import("../containers/Login").then(module => module.default))
	},
	{
		path: "/error",
		exact: true,
		main: asyncComponent(() => import("../pages/NotFound").then(module => module.default))
	},
	{
		main: asyncComponent(() => import("../pages/NotFound").then(module => module.default))
	}
];

export default routes;