export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_MENU = "SET_MENU";
export const SET_ALL_SALEMAN = "SET_ALL_SALEMAN";
export const LOGIN = "LOGIN";
export const SIGN_OUT = "SIGN_OUT";
export const SET_SHOPS = "SET_SHOPS";
export const SET_ALL_COLLECTOR = "SET_ALL_COLLECTOR";
export const SET_ALL_SHOP_OWNER = "SET_ALL_SHOP_OWNER";

export const SET_COLLECTOR_GROUPS = "SET_COLLECTOR_GROUPS";
export const SET_BORROWERS = "SET_BORROWERS";
export const SET_COLLECTORS = "SET_COLLECTORS";
export const SET_AREAS = "SET_AREAS";
export const SET_ADMINS = "SET_ADMINS";
