import * as Types from './../constants/ActionType';

const user = localStorage.getItem("user");

let initialState = {
	isLoggedIn: user !== null,
	user: user !== null ? JSON.parse(user) : {}
}; // String, Array, Object,...

const auth = (state = initialState, action) => {

    switch(action.type) {
			case Types.LOGIN:
				const { username, accessToken, _id, role } = action.user;
				state.user = {
					username,
					accessToken,
					_id,
					role
				};
				localStorage.setItem("user", JSON.stringify(state.user));
				return {
					...state,
					isLoggedIn: true
				};
			case Types.SIGN_OUT:
				localStorage.removeItem("user");
				return {
					...state,
					isLoggedIn: false,
					user: {}
				};
			default:
					return state; // copy new array and return it
    }

}

export default auth;