import React, { Component, Fragment } from "react";
import "./App.css";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";


import routes from "./routes";
import SidebarContainer from "./containers/Sidebar";
import HeadbarContainer from "./containers/Headbar";
import LoginContainer from "./containers/Login";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			routes: this.generateRoutes()
		}
	}

	generateRoutes = () => {
		return routes.map((route, index) => (
			<Route
				key={ index }
				path={ route.path }
				exact={ route.exact }
				component={ route.main }
			/>
		))
	}

  render() {

		const { sidebar, auth, location } = this.props;
		if(!auth.isLoggedIn && location.pathname !== "/login") {
			return (<Redirect to="/login" />)
		}

		const {
			routes
		} = this.state;

    return (
			<Fragment>
				{ (location.pathname === "/login")
					?	(<Route path="/login" component={() => <LoginContainer />} />)
					: (	
							<div className={"App " + (sidebar.isShow ? "fixed-sidebar" : "")}>
								<HeadbarContainer />
								<SidebarContainer />
								<div id="main-app">
									<Switch>
										{ routes }
									</Switch>
									<NotificationContainer />
								</div>
							</div>
					)}
			</Fragment>
    );
  }
}

const mapStateToProps = state => {
	return {
		sidebar: state.sidebar,
		auth: state.auth
	}
}

export default withRouter(connect(mapStateToProps, null)(App));
