import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;

const user = localStorage.getItem("user");
let headers = {
	"Content-Type": "application/json"
};

if(user) {
	const userJson = JSON.parse(user);
	if(userJson && userJson.accessToken) {
		headers["Authorization"] = userJson.accessToken;
	}
}

export const setHeaders = (accessToken) => {
	headers = {
		...headers,
		Authorization: accessToken
	}
	return headers;
}

export const login = ({ body = null }) => {
	return axios({
		method: "POST",
		url: `${API_URL}/auth/login`,
		data: body,
		headers
	}).then(res => {
		return res.data;
	}).catch(err => {
		console.log(err);
	})
}

export const getApi = ({ endpoint, body = null }) => {
	const url = (!body || Object.keys(body).length === 0) ? endpoint : `${endpoint}?${URLStringify(body)}`;
	return axios({
			method: "GET",
			url: `${API_URL}/${API_VERSION}/${url}`,
			headers
	}).then(res => {
		return res.data;
	}).catch(err => {
		const response = err.response;
		if(response.data && response.data.description === "invalid signature") {
			localStorage.removeItem("user");
			window.location.reload();
			return;
		}
		return {
			code: err.code || response.status || 100,
			description: response.data.message || "Hệ thống đang cập nhật. Vui lòng thử lại sau."
		};
	})
}

export const postApi = ({ endpoint, body = null, method = "POST" }) => {
	return axios({
		method,
		url: `${API_URL}/${API_VERSION}/${endpoint}`,
		data: body,
		headers
	}).then(res => {
		return res.data;
	}).catch(err => {
		const response = err.response;
		return {
			code: err.code || response.status || 100,
			description: response.data.message || "Hệ thống đang cập nhật. Vui lòng thử lại sau."
		};
	})
}

export const putApi = ({ endpoint, body = null }) => {
	return axios({
		method: "PUT",
		url: `${API_URL}/${API_VERSION}/${endpoint}`,
		data: body,
		headers
	}).then(res => {
		return res.data;
	}).catch(err => {
		const response = err.response;
		return {
			code: err.code || response.status || 100,
			description: response.data.message || "Hệ thống đang cập nhật. Vui lòng thử lại sau."
		};
	})
}

export const deleteApi = ({ endpoint, body = null }) => {
	return axios({
		method: "DELETE",
		url: `${API_URL}/${API_VERSION}/${endpoint}`,
		data: body,
		headers
	}).then(res => {
		return res.data;
	}).catch(err => {
		const response = err.response;
		return {
			code: err.code || response.status || 100,
			description: response.data.message || "Hệ thống đang cập nhật. Vui lòng thử lại sau."
		};
	})
}

export const callApi = ({ endpoint, method = 'GET', body = null }) => {
	return axios({
		method: method,
		url: `${API_URL}/${API_VERSION}/${endpoint}`,
		data: body
	}).then(res => {
		return res.data;
	}).catch(err => {
		const response = err.response;
		if(response.data && response.data.description === "invalid signature") {
			localStorage.removeItem("user");
			window.location.reload();
			return;
		}
		return {
			code: err.code || response.status || 100,
			description: response.data.message || "Hệ thống đang cập nhật. Vui lòng thử lại sau."
		};
	})
}

export const uploadFile = ({ endpoint, formData }) => {
	return axios({
		method: "POST",
		url: `${API_URL}/upload/${endpoint}`,
		data: formData,
		headers: {
			"Content-Type": "multipart/form-data"
		}
	}).then(res => {
		return res.data;
	}).catch(err => {
		console.log(err);
	})
}


const URLStringify = body => {
	const result = Object.keys(body).map(key => {
		const value = body[key];
		return `${key}=${value}`;
	});
	return result.join("&");
}